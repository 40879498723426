<template>
  <div v-if="false">
    <h1>Stay safe: keep social distance</h1>

    <div v-if="article" v-html="article.content" class="markdown mx-0 mt-4 dynamic-content"> </div>
  </div>
</template>

<script>

import articleService from "@/services/articleService";
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "SocialDistancing",
  components: {
  },
  props: {
  },
  data: function() {
    return {
      article: null,
      tenant: tenant,
    };
  },
  created() {
  },
  async mounted() {
    this.article = await articleService.get('socialdistancing');

    if (this.article == null) {
      // todo: redirect to 404
    }
  },
  methods: {
  },
  watch: {
  }
};
</script>

<style scoped>
</style>